import axios from 'axios';
import $auth from "./auth";

const CURRENT_USER_URI = '/user/current';
const PROFILE_PICTURE_URI = '/user/profilePicture/';
const USER_URI = '/user';
const APP_CONFIGURATION_URI = '/app_configuration';
const FIND_MENU_URI = '/menu/loadMenu/';

const {
    VUE_APP_API_URL
// eslint-disable-next-line no-undef
} = process.env;

const getCurrentUser = (token) => {
    const url = `${VUE_APP_API_URL}/security${CURRENT_USER_URI}`;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + token.access_token
        },
    }).then(response => response.data.principal.user).catch((error) => {});
};

const getProfilePicture = (token) => {
    const url = `${VUE_APP_API_URL}/security${PROFILE_PICTURE_URI}`;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + token.access_token
        },
    }).then(response => response.data).catch((error) => {});
};

const updateProfilePicture = (token, data) => {
    const url = `${VUE_APP_API_URL}/security${PROFILE_PICTURE_URI}`;

    return axios({
        url: url,
        method: 'put',
        headers: {
            Authorization: 'Bearer ' + token.access_token,
            'Content-Type': 'application/json'
        },
        data: {
            imgContent: data,
        },
    });
};

const updateUser = (user) => {
    const url = `${VUE_APP_API_URL}/security${USER_URI}/${user.id}`;

    return axios({
        url: url,
        method: 'put',
        headers: {
            Authorization: 'Bearer ' + $auth.getAccessToken(),
            'Content-Type': 'application/json'
        },
        data: user,
    });
};

const loadMenu = (token) => {
    const url = `${VUE_APP_API_URL}/security${FIND_MENU_URI}`;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + token.access_token
        },
    }).then(response => response.data).catch((error) => {});
};


const logoutSession = () => {
    const url = `${VUE_APP_API_URL}/security${USER_URI}/logout`;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + $auth.getAccessToken(),
            'Content-Type': 'application/json'
        },
    });
};

const getAppConfigurations = (entityID, entityType, token) => {
    const url = `${VUE_APP_API_URL}/security${APP_CONFIGURATION_URI}/entityID/${entityID}/entityType/${entityType}`;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    });
};

const getAppAndRelatedFinancialGroupConfigurations = (appCode, financialGroupID, token) => {
    const url = `${VUE_APP_API_URL}/security${APP_CONFIGURATION_URI}/getAppConfigurationsAndRelatedFinancialGroupConfigurations`;

    return axios({
        url: url,
        method: 'get',
        params: {
            appCode: appCode,
            financialGroupID: financialGroupID,
        },
        headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    });
};

const saveAppConfigurations = (appConfigurations, token) => {
    const url = `${VUE_APP_API_URL}/security${APP_CONFIGURATION_URI}/saveAppConfigurations`;

    return axios({
        url: url,
        method: 'post',
        headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        data: [...appConfigurations]
    });
};

export {
    getCurrentUser,
    loadMenu,
    getProfilePicture,
    updateProfilePicture,
    updateUser,
    logoutSession,
    getAppConfigurations,
    getAppAndRelatedFinancialGroupConfigurations,
    saveAppConfigurations
};
