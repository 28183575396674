import {
  formmasks
} from './formmasks'

const formrules = {
  required: value => !!value || "Preenchimento obrigatório.",
  celular: value => {
    var isvalid = false;
    if (value) {
      const match = formmasks.unmask(value).match(/\d/g);
      if (match)
        isvalid = match.length >= 8
    }

    return isvalid || "Telefone inválido"
  },
  ddd: value => {
    var isvalid = false;
    if (value) {
      const match = formmasks.unmask(value).match(/\d/g);
      if (match)
        isvalid = match.length == 2
    }

    return isvalid || "DDD inválido"
  },
  countrycode: value => {
    var isvalid = false;
    if (value) {
      const match = formmasks.unmask(value).match(/\d/g);
      if (match)
        isvalid = match.length == 2
    }

    return isvalid || "Código do País inválido"
  },
  range1a100: value => {
    return _range(value, 1, 100);
  }
}

const _range = (value, min, max) => {
  var isvalid = false;
  var msg = "Valor inválido";

  if (value) {
    if (!(value >= min)) {
      msg = "Preencha com algo maior que " + min
    } else if (!(value <= max)) {
      msg = "Preencha com algo menor que " + max
    }else isvalid = true
  }

  return isvalid || msg
}

export {
  formrules
}