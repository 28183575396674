<template>
  <v-menu
      ref="menu1"
      v-model="menu1"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          :label="label"
          :disabled="disabled"
          v-model="dateFormatted"
          persistent-hint
          :prepend-icon="showPrependIconDate ? 'mdi-calendar' : null"
          v-bind="attrs"
          @blur="date = parseDate(dateFormatted)"
          dense
          v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
        v-model="date"
        no-title
        @input="menu1 = false"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "index",
  data: vm => ({
    date: new Date().toISOString().substr(0, 10),
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    menu1: false,
    menu2: false,
  }),

  computed: {
    computedDateFormatted () {
      return this.formatDate(this.date)
    },
  },

  watch: {
    date (val) {
      this.dateFormatted = this.formatDate(this.date)
    },
  },
  props: {
    label:{
      type: String,
      default: ''
    },
    showPrependIconDate: {
      type: Boolean,
      default: true,
    },
    dateProp :{
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    formatDate (date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');

      this.updateDate(date);

      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [day, month, year] = date.split('/');

      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    updateDate(date){
      this.$emit('updateDate', date);
    }
  },
  created() {
    this.dateFormatted = this.dateProp && this.formatDate(this.dateProp)
  }
}
</script>

<style scoped>

</style>