const defaultDelimiters = /[-!$%^&*()_+|~=`{}[\]:";'<>?,./\\ ]/

const formmasks = {
  money: ["#,##","##,##","###,##","####,##", "#####,##"],
  cpf: "###.###.###-##",
  countrycode: ['+##'],
  ddd: ['(##)'],
  celular: ['####-####', '#####-####'],
  dddTel: ['(##) ####-####', '(##) #####-####'],
  unmask: text => {
    return text ? String(text).replace(new RegExp(defaultDelimiters, 'g'), '') : text
  }
}

export {
  formmasks
}
