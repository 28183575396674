import Vue from 'vue';
import VueRouter from 'vue-router';
import $store from '../store/store'


import Install from '@/view/install/index.vue';
import AppConfigurationForm from "../view/config-app/appConfigurationForm.vue";
import BeneficiaryDetail from '../view/atendimento/beneficiaryDetail.vue';
import MixedAppConfigurationForm from "../view/config-app/mixedAppConfigurationForm.vue";
import PostApp from "../view/admin/post/post";
import PostEditApp from "../view/admin/post/post-edit";
import TargetEditApp from "../view/admin/target-public/target-public-inserir";

Vue.use(VueRouter)


const routes = [
    {
        name: 'install',
        path: '/install',
        components: {
            container: Install,
        },
        meta: {
            auth: false,
        }
    },
    {
        name: 'appointment',
        path: '/appointment',
        components: {
            container: () => import('../view/appointment/Appointment.vue'),
        },
        meta: {
            auth: true,
            root: true
        },
    },
    {
        name: 'vaccine',
        path: '/vaccine',
        components: {
            container: () => import('../view/vaccine/Vaccine.vue'),
        },
        meta: {
            auth: true,
            root: true
        },
    },
    {
        name: 'exames',
        path: '/exames',
        components: {
            container: () => import('../view/exame/exame.vue'),
        },
        meta: {
            title: 'Exames',
            root: false,
            toolbarBehavior: true
        }
    },
    {
        name: 'knowledge-article',
        path: 'knowledge-article',
        components: {
            container: () => import('./../view/ajuda/knowledgeArticles.vue'),
        },
        meta: {
            title: 'Artigo',
            root: true,
        }
    },
    {
        name: 'timelineStory',
        path: '/timeline-story',
        components: {
            container: () => import('../components/protecoes/bookmarkScreen.vue'),
        },
        meta: {
            title: 'Timeline',
            root: false,
            toolbarBehavior: true
        },
    },
    {
        name: 'atendimentoOperadora',
        path: '/atendimentoOperadora',
        components: {
            container: () => import('../view/refund/RefundIntro.vue')
        }
    },
    {
        name: 'about',
        path: '/about',
        components: {
            container: () => import('../view/opcoes/about'),
        },
        meta: {
            auth: true,
            root: true
        },
    },
    {
        name: 'account',
        path: '/account',
        components: {
            container: () => import('../view/admin/account/account.vue'),
        },
        meta: {
            auth: true,
            root: true
        },
    },
    {
        name: 'atendimentos',
        path: '/atendimentos',
        components: {
            container: () => import('../view/atendimento/casesView.vue'),
        },
        meta: {
            title: 'Atendimentos',
            root: false,
            toolbarBehavior: true
        }
    },
    {
        name: 'atendimentosOptions',
        path: '/atendimentos/opcoes',
        components: {
            container: () => import('../view/atendimento/caseOptions.vue'),
        },
            meta: {
            title: 'Opções de Atendimento',
            root: false,
            toolbarBehavior: true
        }
    },
    {
        name: 'addCase',
        path: '/atendimentos/cadastrar',
        components: {
            container: () => import('./../view/atendimento/caseForm.vue'),
        },
            props: (route) => ({
            optionTitle: route.params.optionTitle,
            optionOptions: route.params.optionOptions
        }),
        meta: {
            title: 'Solicitar Atendimento',
            bch: ['atendimentos']
        }
    },
    {
        name: 'inseriratendimento',
        path: '/atendimento/inserir/:solicitacao',
        components: {
            container: () => import('./../view/atendimento/atendimentoInserir.vue'),
        },
            props: (route) => ({
            solicitacao: route.params.solicitacao
        }),
        meta: {
            title: 'Solicitar Atendimento',
            bch: ['atendimentos']
        }
    },
    {
        name: 'atendimento',
        path: '/atendimento/:id',
        components: {
            container: () => import('./../view/atendimento/atendimentoDetalhar.vue'),
        },
            props: (route) => ({
            id: route.params.id
        }),
        meta: {
            title: 'Detalhar Atendimento',
            bch: ['atendimentos']
        }
    },
    {
        name: 'atendimento-addEvent',
        path: '/atendimento/:id/addEvent',
        components: {
            container: () => import('./../view/atendimento/caseAddEvent.vue'),
        },
        props: (route) => ({
            id: route.params.id
        })
    },
    {
        name: 'UserTerms',
        path: '/userTerms',
        components: {
            container: () => import( /* webpackChunkName: "politicadeprivacidade" */ './../view/opcoes/userTerms.vue'),
        },
        meta: {
            auth: false,
        }
    },
    {
        name: 'not-install',
        path: '/not-install',
        components: {
            container: () => import('../view/app/notInstall'),
        },
        meta: {
            auth:false,
            root:false,
        }
    },
    {
        name: 'atualizar-senha',
        path: '/atualizar-senha',
        components: {
        container: () => import('../view/ajuda/atualizarSenha.vue'),
        },
        meta: {
            auth:true,
            root:true,
        }
    },
    {
        name: 'miniHomeCovid',
        path: '/miniHomeCovid',
        components: {
            container: () => import('../view/covid/miniHomeCovid.vue'),
        },
        meta: {
            auth: true,
            root: true
        },
    },
    {
        name: 'message',
        path: '/message',
        components: {
            container: () => import('../view/message/index.vue'),
        },
        meta: {
            auth: true,
            root: true
        },
    },
    {
        name: 'notifications',
        path: '/notifications',
        components: {
            container: () => import('../view/message/notifications.vue'),
        },
        meta: {
            auth: true,
            root: true
        },
    },
    {
        name: 'chat',
        path: '/chat',
        components: {
            container: () => import('../view/message/chat.vue'),
        },
        meta: {
            auth: true,
            root: true
        },
    },
    {
        name: 'perfil',
        path: '/perfil',
        components: {
            container: () => import('./../view/opcoes/perfil.vue'),
        },
        meta: {
            title: 'Meu Perfil',
            bch: ['opcoes']
        }
    },
    {
        name: 'wallet',
        path: '/wallet',
        components: {
            container: () => import('@/view/wallet/wallet'),
        },
        props: true,
        meta: {
            auth: true,
            root: true
        }
    },
    {
        name: 'walletDetailCards',
        path: '/walletDetailCards',
        components: {
            container: () => import('@/view/wallet/walletDetailCards'),
        },
        props: true,
        meta: {
            auth: true,
            root: true
        }
    },
    {
        name: 'survey',
        path: '/survey',
        components: {
            container: () => import('@/components/survey/index'),
        },
        props: true,
        meta: {
            auth: true,
            root: true
        }
    },
    {
        name: 'quizResult',
        path: '/quizResult',
        components: {
            container: () => import('@/components/quiz-result/index'),
        },
        meta: {
            auth: true,
            root: true
        }
    },
    {
        name: 'historicoTriagem',
        path: '/historicoTriagem',
        components: {
            container: () => import('../view/covid/screeningHistory.vue'),
        },
        meta: {
            auth: true,
            root: true
        },
    },
    {
        name: 'finalizarTriagem',
        path: '/finalizarTriagem',
        components: {
            container: () => import('@/components/screening/finishScreening'),
        },
        meta: {
            auth: true,
            root: true
        }
    },
    {
        name: 'qrCodeTriagem',
        path: '/qrCodeTriagem',
        components: {
            container: () => import('@/components/screening/qrCode'),
        },
        meta: {
            auth: true,
            root: true
        }
    },
    {
        name: 'orientacoesCovid',
        path: '/orientacoesCovid',
        components: {
            container: () => import('@/components/screening/covidGuidance'),
        },
        meta: {
            auth: true,
            root: true
        }
    },
    {
        name: 'trilhaDeConhecimento',
        path: '/trilhaDeConhecimento',
        components: {
            container: () => import('@/view/covid/knowledgeTrail'),
        },
        meta: {
            auth: true,
            root: true
        }
    },
    {
        name: 'triagem',
        path: '/triagem',
        components: {
            container: () => import('@/view/covid/screening'),
        },
        meta: {
            auth: true,
            root: true
        }
    },
    {
        name: 'configMixed',
        path: '/config-mixed',
        components: {
            container : MixedAppConfigurationForm,
        },
        meta: {
            title: 'Configurações do App, Grupo Financeiro e Contratos',
            root: true,
            auth: false,
            classMainContainer: 'empty-background',
        },
    },
    {
        name: 'beneficiaryDetail',
        path: '/beneficiary-detail',
        components: {
            container : BeneficiaryDetail,
        },
        meta: {
            title: 'Detalhes do Beneficiário',
            root: true,
            auth: false,
            classMainContainer: 'empty-background',
        },
    },
    {
        name: 'configApp',
        path: '/config-app',
        components: {
            container : AppConfigurationForm,
        },
        meta: {
            title: 'Configurações do APP',
            root: true,
            auth: false,
            classMainContainer: 'empty-background',
            entityType : 'APP'
        },
    },
    {
        name: 'configFinancialGroup',
        path: '/config-financial-group',
        components: {
            container : AppConfigurationForm,
        },
        meta: {
            title: 'Configurações de Grupo Fincanceiro',
            root: true,
            auth: false,
            classMainContainer: 'empty-background',
            entityType : 'FINANCIAL_GROUP'
        },
    },
    {
        name: 'configContract',
        path: '/config-contract',
        components: {
            container : AppConfigurationForm,
        },
        meta: {
            title: 'Configurações de Contrato',
            root: true,
            auth: false,
            classMainContainer: 'empty-background',
            entityType : 'CONTRACT'
        },
    },
    {
        name: 'logout',
        path: '/logout',
        redirect: {
            name: 'login'
        },
        meta: {
            auth: false,
        }
    },
    {
        name: 'publicacoes',
        path: '/publicacoes',
        components: {
            container: PostApp
        },
        meta: {
            auth: false,
            root: true,
            classMainContainer: 'empty-background',
            entityType : 'APP',
            skipInstall: true
        },
    },
    {
        name: 'publicacao-edit',
        path: '/publicacao/:id',
        components: {
            container: PostEditApp
        },
        meta: {
            auth: false,
            root: false,
            classMainContainer: 'empty-background',
            entityType : 'APP',
            skipInstall: true
        },
    },
    {
        name: 'tips',
        path: '/tips',
        components: {
            container: () => import('../view/tips/TipsMenu.vue'),
        },
    },
    {
        name: 'tip-detail',
        path: '/tip-detail/:id',
        components: {
            container: () => import('../view/tips/TipDetail.vue'),
        },
    },
    {
        name: 'target-edit',
        path: '/target-upsert/:feedItemId/:id',
        components: {
            container: TargetEditApp
        },
        meta: {
            auth: false,
            root: true,
            classMainContainer: 'empty-background',
            entityType : 'APP',
            skipInstall: true
        },
    },
    {
        name: 'root',
        path: '/',
        components: {
            container: () => import('./../view/app/portal.vue'),
        },
        redirect: (route) => ({
            path: '/home',
        }),
        children: [
            {
                name: 'login',
                path: 'login',
                components: {
                    keepalive: () => import('./../view/app/login.vue'),
                },
                meta: {
                    auth: false,
                    root: true,
                }
            },
            {
                name: 'protecoes',
                path: 'minhas-protecoes:activeTab?/:showCadastroDialog?',
                component: () => import('../view/home/protecoes.vue'),
                props: (route) => ({
                    activeTab: (route.params.activeTab ? parseInt(route.params.activeTab) : 0),
                    showCadastroDialog: (route.params.showCadastroDialog ? true : false)
                }),
                meta: {
                    title: 'Meus Benefícios',
                    root: true,
                    forcetoolbar: true
                },
            },
            {
                name: 'home',
                path: '/home',
                component: () => import('./../view/home/home.vue'),
                meta: {
                    title: '',
                    bch: ['home'],
                    tour: true
                },
            }
            ,
            {
                name: 'home-tour',
                path: '/home?showTour=true',
                component: () => import('./../view/home/home.vue'),
                meta: {
                    title: '',
                    bch: ['home'],
                    tour: true
                },
            },
            {
                name: 'detail-subiten-protecoes',
                path: 'detail-subiten-protecoes/:title&:id&:perfil&:nome&:index/:tipo?',
                component: () => import('../view/home/protecaoItem.vue'),
                props: (route) => ({
                    id: route.params.id,
                    perfil: route.params.perfil,
                    index: parseInt(route.params.index),
                    tipoItem: route.params.tipo,
                    nome: route.params.nome,
                    item: route.params.item
                }),
                meta: {
                    title: '',
                    bch: ['protecoes'],
                    tour: true
                },
            },
            {
                name: 'new-subiten-protecoes',
                path: 'new-subiten-protecoes/:title/:tipo?',
                props: (route) => ({
                    newItem: true,
                    tipoItem: route.params.tipo
                }),
                component: () => import('../view/home/protecaoItem.vue'),
                meta: {
                    title: '',
                    bch: ['protecoes']
                },
            },
            {
                name: 'agendamentos',
                path: 'agendamentos',
                component: () => import('./../view/produto/agendamentos.vue'),
                meta: {
                    title: 'Agendamentos',
                    toolbarBehavior: true
                }
            },
            {
                name: 'produtos',
                path: 'produto',
                components: {
                    keepalive: () => import('./../view/produto/produto.vue'),
                },
                meta: {
                    title: 'Ficar protegido',
                    root: true,
                    auth: true,
                },
            },
            {
                name: 'cotador',
                path: 'cotador',
                components: {
                    keepalive: () => import('./../view/cotador/cotador.vue'),
                },
                meta: {
                    title: 'Cotador',
                    root: true,
                    auth: false,
                },
            },
            {
                name: 'comprarProduto',
                path: 'comprar-produto/:id',
                component: () => import( /* webpackChunkName: "comprarProduto" */ './../view/produto/produtoComprar.vue'),
                props: (route) => ({
                    id: route.params.id
                }),
                meta: {
                    title: 'Comprar Produto',
                    auth: true,
                    bch: ['produtos']
                },
            },
            {
                name: 'atendimentos',
                path: 'atendimentos',
                component: () => import('../view/atendimento/casesView.vue'),
                meta: {
                    title: 'Atendimentos',
                    root: false,
                    toolbarBehavior: true
                }
            },
            {
                name: 'atendimentosOptions',
                path: 'atendimentos/opcoes',
                component: () => import('../view/atendimento/caseOptions.vue'),
                meta: {
                    title: 'Opções de Atendimento',
                    root: false,
                    toolbarBehavior: true
                }
            },
            {
                name: 'addCase',
                path: 'atendimentos/cadastrar',
                component: () => import('./../view/atendimento/caseForm.vue'),
                props: (route) => ({
                    optionTitle: route.params.optionTitle,
                    optionOptions: route.params.optionOptions
                }),
                meta: {
                    title: 'Solicitar Atendimento',
                    bch: ['atendimentos']
                }
            },
            {
                name: 'inseriratendimento',
                path: 'atendimento/inserir/:solicitacao',
                component: () => import('./../view/atendimento/atendimentoInserir.vue'),
                props: (route) => ({
                    solicitacao: route.params.solicitacao
                }),
                meta: {
                    title: 'Solicitar Atendimento',
                    bch: ['atendimentos']
                }
            },
            {
                name: 'atendimento',
                path: 'atendimento/:id',
                component: () => import('./../view/atendimento/atendimentoDetalhar.vue'),
                props: (route) => ({
                    id: route.params.id
                }),
                meta: {
                    title: 'Detalhar Atendimento',
                    bch: ['atendimentos']
                }
            },
            {
                name: 'operadoras',
                path: 'operadoras',
                component: () => import('./../view/produto/operadoras.vue'),
                meta: {
                    title: 'Operadoras',
                    bch: ['atendimentos']
                }
            },
            {
                name: 'avisos',
                path: 'aviso',
                component: () => import('./../view/aviso/aviso.vue'),
                meta: {
                    title: 'Avisos',
                    root: false,
                }
            },
            {
                name: 'pontos',
                path: 'ponto',
                component: () => import('./../view/pontos/ponto.vue'),
                meta: {
                    title: 'Pontos',
                    root: false,
                }
            },
            {
                name: 'ajuda',
                path: 'ajuda',
                component: () => import('./../view/ajuda/ajuda.vue'),
                meta: {
                    title: 'ajuda',
                    root: true,
                }
            },
            {
                name: 'assistenteboot',
                path: 'assistente/:boot',
                component: () => import('./../view/ajuda/assistente.vue'),
                props: (route) => ({
                    booturl: route.params.boot
                }),
                meta: {
                    title: 'Assistente',
                    root: false,
                }
            },
            {
                name: 'ajuda2',
                path: 'ajuda2',
                component: () => import('./../view/ajuda/ajuda2.vue'),
                meta: {
                    title: 'ajuda2',
                    root: false,
                }
            },
            {
                name: 'menu-nick',
                path: 'menu-nick',
                component: () => import('./../view/ajuda/menuNick.vue'),
                meta: {
                    title: 'ajuda2',
                    root: false,
                    tour: true,
                    falarnick: true
                }
            },
            {
                name: 'principais-duvidas',
                path: 'principais-duvidas',
                component: () => import('./../view/ajuda/principais-duvidas.vue'),
                meta: {
                    title: 'Principais Dúvidas',
                    root: false,
                }
            },
            {
                name: 'dicas-nick',
                path: 'dicas-nick',
                component: () => import('./../view/ajuda/dicas-nick.vue'),
                meta: {
                    title: 'Dicas do Nick',
                    root: false,
                }
            },
            {
                name: 'opcoes',
                path: 'opcoes',
                component: () => import('./../view/opcoes/opcoes.vue'),
                meta: {
                    title: 'Opções',
                    root: true,
                }
            },
            {
                name: 'perfil',
                path: 'perfil',
                component: () => import('./../view/opcoes/perfil.vue'),
                meta: {
                    title: 'Meu Perfil',
                    bch: ['opcoes']
                }
            },
            {
                name: 'perfilUpdate',
                path: 'perfilUpdate',
                component: () => import('./../view/opcoes/perfil.vue'),
                meta: {
                    title: 'Atualizar Perfil',
                    bch: ['opcoes']
                }
            },
            {
                name: 'minha-empresa',
                path: 'minha-empresa',
                component: () => import('./../view/opcoes/MinhaEmpresa.vue'),
                meta: {
                    title: 'Minha Empresa',
                    bch: ['opcoes']
                }
            },
            {
                name: 'conectarComSuaEmpresa',
                path: 'conectarComSuaEmpresa',
                component: () => import('./../view/opcoes/ConectarComSuaEmpresa.vue'),
                meta: {
                    title: 'Minha Empresa',
                    bch: ['opcoes']
                }
            },
            {
                name: 'dependenteConexaoEmpresa',
                path: 'dependenteConexaoEmpresa',
                component: () => import('./../view/opcoes/DependenteConexaoEmpresa.vue'),
                meta: {
                    title: 'Minha Empresa',
                    bch: ['opcoes']
                }
            },
            {
                name: 'fullScreenImg',
                path: 'info-img/:title&:url',
                component: () => import( /* webpackChunkName: "fullScreenImg" */ './../view/produto/fullScreenImg.vue'),
                props: (route) => ({
                    url: route.params.url
                }),
            },
            {
                name: 'descontos-qr',
                path: 'descontos-qr',
                component: () => import('./../view/produto/descontosqr.vue'),
                meta: {
                    title: 'Descontos com código QR'
                }
            },
            {
                name: 'assesment',
                path: 'assesment',
                component: () => import('./../view/produto/assesments.vue'),
                meta: {
                    title: 'Assesment'
                }
            },
            {
                name: 'notfound',
                path: 'notfound',
                component: () => import('./../view/app/notfound.vue'),
                meta: {
                    title: '',
                    auth: false,
                }
            },
            {
                name: 'loginbygoogle',
                path: 'loginbygoogle',
                component: () => import('./../view/app/loginbygoogle.vue'),
                meta: {
                    auth: false,
                }
            },
            {
                name: 'Extrato de Utilização',
                path: 'extrato-de-utilizacao',
                component: () => import( /* webpackChunkName: "extrato-de-utilizacao" */ './../view/extrato-de-utilizacao/index.vue'),
                meta: {
                    title: 'Extrato de Utilização',
                    auth: true,
                },
            },
        ],
    },
    {
        path: '*',
        redirect: {
            name: 'notfound'
        },
        meta: {
            auth: false,
        }
    },
{
    name: 'Acess External',
    path: '/external-acess/:token/:linkRedirect',
    components: {
        container: () => import('./../view/external/acess.vue'),
    },
    meta: {
        auth: false,
    },
},
{
    name: 'publicacoes',
    path: '/publicacoes',
    components: {
        container: PostApp
    },
    meta: {
        auth: false,
        root: true,
        classMainContainer: 'empty-background',
        entityType : 'APP'
    },
},
{
    name: 'publicacao-edit',
    path: '/publicacao/:id',
    components: {
        container: PostEditApp
    },
    meta: {
        auth: false,
        root: true,
        classMainContainer: 'empty-background',
        entityType : 'APP'
    },
},
{
    name: 'tips',
    path: '/tips',
    components: {
        container: () => import('../view/tips/TipsMenu.vue'),
    },
},
{
    name: 'tip-detail',
    path: '/tip-detail/:id',
    components: {
        container: () => import('../view/tips/TipDetail.vue'),
    },
},
{
    path: '*',
    redirect: {
        name: 'notfound'
    },
    meta: {
        auth: false,
    }
}
]

const router = new VueRouter({
    mode: 'history',
    // eslint-disable-next-line no-undef
    base: process.env.BASE_URL,
    routes: routes
})

router.beforeEach((to, from, next) => {
    $store.commit('hidemsg');
    next();
})

export default router
