<template>
  <v-app id="eu_protegido" :style="cssTheme" :class="bgColor">
    <template>
      <msg />
      <Loading />
      <router-view name="container" :key="$route.fullPath"></router-view>
      <keep-alive>
        <router-view name="containerkeepalive"></router-view>
      </keep-alive>
      <version-update />
    </template>
  </v-app>
</template>

<script>
import msg from "./components/structure/msg.vue";
import { mapMutations } from "vuex";
import Loading from "./components/structure/Loading";
import VersionUpdate from "./components/structure/VersionUpdate";

export default {
  methods: {
    ...mapMutations(["updateMenuState", "updateStructure"]),
    initialize() {
      this.checkAppInstalationStatus();
      let session = this.$auth.getSession();
      if (session && session.user) this.$store.commit("user", session.user);
    },
    checkSecurity() {
      if (this.$util.checkIndex())
        this.$auth.logout();
      else
        this.$auth.checkAuthentication();
    },
    async checkAppInstalationStatus() {
      const isInstalled = navigator.standalone || window.matchMedia('(display-mode: standalone)').matches;
      const { skipInstall } = this.$route.meta;
      if (isInstalled || process.env.NODE_ENV === 'development' || skipInstall)
      {
        this.checkSecurity();
        return;
      }
      this.$router.push('/install');
    },
  },
  watch: {
    $route() {
      this.checkAppInstalationStatus();
    },
  },

  created() {
    this.checkSessionTokenExists = window.location.search.split('token=').length > 1;
  },
  mounted() {
    // this.notificationsPermisionRequest();
    //this.listenTokenRefresh();
    //this.getMessagingToken();
    //this.initialize();
  },
  computed: {
    cssTheme() {
      // espaço da barra de menu inferiro
      const heightBottom = this.$util.isIosMobile()
        ? "75px"
        : this.$util.isMobile()
        ? "70px"
        : "36px";
      const paddingBottom = this.$util.isIosMobile() ? "5px" : "0px";
      this.updateStructure({ heightBottom, paddingBottom });

      const out = {};
      out["--v-heightBottom"] = heightBottom;
      out["--v-paddingBottom"] = paddingBottom;

      for (const name of Object.keys(this.$vuetify.theme.themes.light))
        out["--v-" + name] = this.$vuetify.theme.themes.light[name];

      return out;
    },

    bgColor() {
      return this.$route.meta.classMainContainer;
    }
  },
  components: { VersionUpdate, Loading, msg },
};
</script>

<style>
/* Workarround https://github.com/vuetifyjs/vuetify/issues/13599 */
.v-btn--disabled {
  pointer-events: unset !important;
  cursor: default;
}
</style>

<style lang="scss">
@import "public/styles/app.scss";

/* utils */
.clearBoth {
  clear: both;
}
.v-application {
  //background-color: var(--v-appbgcolor) !important;
  background: var(--v-appgradientcolor) !important;
}

.v-dialog:not(.v-dialog--fullscreen) {
  border-radius: 40px !important;
}

.empty-background{
  background-color: white !important;
}

</style>
