<template>
    <v-overlay :value="loading" :opacity="0.5" z-index="9999">
        <!--<v-progress-circular indeterminate size="64" color="red" />-->
        <v-row align="center" justify="center" style="width: 100vw; height: 100vh;">
          <v-col cols="12" align="center">
            <v-row class="fadein" justify="center">
              <img id="img1" src="/img/loading-step1.svg">
              <img id="img2" src="/img/loading-step2.svg">
              <img id="img3" src="/img/loading-step3.svg">
            </v-row>
            <v-row justify="center"></v-row>
          </v-col>
        </v-row>
    </v-overlay>
</template>

<script>
    export default {
      name: "Loading",
      computed:{
        loading(){
          return this.$store.state.app.loading;
        }
      },
    }
</script>

<style scoped>

.fadein img {
  position:absolute;
  left: 10%;
  top: 33%;
  -webkit-animation-name: fade;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 4s;
  animation-name: fade;
  animation-iteration-count: infinite;
  animation-duration: 4s;
}

@-webkit-keyframes fade {
  0% {opacity: 0;}
  20% {opacity: 1;}
  33% {opacity: 1;}
  73% {opacity: 0;}
  100% {opacity: 0;}
}
@keyframes fade {
  0% {opacity: 0;}
  20% {opacity: 1;}
  33% {opacity: 1;}
  73% {opacity: 0;}
  100% {opacity: 0;}
}

#img2 {
  -webkit-animation-delay: -2.5s;
}

#img3 {
  -webkit-animation-delay: -1.5s;
}

@media (min-width: 360px) {
  .fadein img {
    left: 15%;
  }
}

@media (min-width: 375px) {
  .fadein img {
    left: 16%;
  }
}

@media (min-width: 411px) {
  .fadein img {
    left: 19%;
  }
}

@media (min-width: 765px) {
  .fadein img {
    left: 32%;
  }
}

@media (min-width: 1000px) {
  .fadein img {
    left: 37%;
  }
}

@media (min-height: 730px) {
  .fadein img {
    top: 36%;
  }
}

@media (min-height: 812px) {
  .fadein img {
    top: 39%;
  }
}

</style>
