import { getCurrentUser, getProfilePicture, loadMenu, logoutSession } from '@/common/securityserver';
import * as Sentry from "@sentry/vue";

var _vm = null;

const SESSION_STORAGE_KEY = 'SESSION';
const LOCALIZED_TOKEN = 'LOCALIZED_TOKEN'
const TERM_FLAG = 'useTermsAccepted';

export default {
  userIsSF() {
    return (this.isAuthenticated() && this.user())
  },
  user() {
    let session = this.getSession();

    if (session && session.user)
      return session.user
    else
      return false;
  },
  setUser(user) {
    Sentry.setUser(user);
    let session = this.getSession();
    Object.assign(session.user, user);
    this.setSession(session);
  },
  getSession() {
    if (localStorage.getItem(SESSION_STORAGE_KEY)) {
      return JSON.parse(localStorage.getItem(SESSION_STORAGE_KEY))
    } else return false
  },
  getLocalizedToken() {
    if (localStorage.getItem(LOCALIZED_TOKEN)) {
      return localStorage.getItem(LOCALIZED_TOKEN)
    } else return false
  },
  setSession(session) {
    if (session) {
      localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(session));
      _vm.$store.commit('user', session.user);
    }
  },
  getAccessToken() {
    return this.getSession().tokenSecurityService.access_token;
  },
  getMenuNick() {
    return this.getMenuByTitle('Nick');
  },
  getMenuOpcoes() {
    return this.getMenuByTitle('Opcoes');
  },
  getMenuByTitle(title){
    var menu = this.getSession().menu && this.getSession().menu.find(m => {
      return m.title === title
    });
    return menu && menu.subMenus;
  },
  getBeneficiaryCards(){
    return this.getSession().user.beneficiaries
  },
  isAuthenticated() {
    var isAuthenticated = false
    const session = this.getSession();
    if (session) {
      const sessionTime = 7200000 //2h
      const mstime = new Date().getTime() - session.createDate
      if (mstime <= sessionTime) {
        isAuthenticated = true
      }
    }

    return isAuthenticated
  },
  askForAuthentication() {
    try {
      if (_vm.$route.matched.length <= 0)
        return false
      else if (_vm.$route.meta && typeof _vm.$route.meta.auth === "boolean")
        return _vm.$route.meta.auth;
    } catch (error) {
      // vazio
    }

    return true;
  },
  checkAuthentication() {
    if (this.askForAuthentication() && !this.isAuthenticated()) {
      _vm.$util.link('login')
    }
  },
  async createSession(tokenSecurityService, options) {

    if (this.getSession())
    {
      localStorage.removeItem(SESSION_STORAGE_KEY);
      localStorage.removeItem('LOCALIZED_SESSION');
      localStorage.removeItem('LOCALIZED_ORIGIN');
    }

    const user = await getCurrentUser(tokenSecurityService);
    let profilePicture = options.profilePicture;
    const menu = await loadMenu(tokenSecurityService);


    await getProfilePicture(tokenSecurityService)
      .then((response) => {
        if (response.imgContent) {
          profilePicture = `data:image;base64,${response.imgContent}`;
          user.hasAccountProfilePicture = true;
        }
      });

    user.tokenSecurityService = tokenSecurityService;
    user.profilePicture = profilePicture ? profilePicture : "https://www.pngfind.com/pngs/m/110-1102775_download-empty-profile-hd-png-download.png";
    user.socialType = options.socialType;

    let session = {
      user,
      tokenSecurityService,
      menu
    }
    let config = null;

    if (options.noRedirect) {
      config = {
        noRedirect: options.noRedirect
      }
    }

    return _afterLogin(Object.assign({}, session), config)

  },
  setCPF(cpf) {
    let session = this.getSession();

    if (session && session.user) {
      session.user.cpf = cpf;
      this.setUser(session.user);
    }
  },
  logout() {
    Sentry.configureScope(scope => scope.setUser(null));
    _logout()
  },
  externalLogin(session, config) {
    return _afterLogin(Object.assign({}, session), config)
  },
  SESSION_STORAGE_KEY: SESSION_STORAGE_KEY,
  TERM_FLAG,
  install(Vue, config) {
    _vm = config.vm

    Vue.prototype.$auth = this
  }
}

const _afterLogin = (session, config) => {

  session.createDate = new Date().getTime()

  _vm.$auth.setSession(session);

  var redirectTo = 'root';
  if (config && config.redirectTo != undefined)
    redirectTo = config.redirectTo;

  if (config && config.noRedirect)
    redirectTo = null;

  if (redirectTo) {
    _vm.$util.link(redirectTo);
    _vm.$store.commit('refreshReloadableKey');
  }

  return session
}

const _logout = () => {

  try{
    logoutSession();
  }catch (error){
    // vazio
  }

  localStorage.removeItem(SESSION_STORAGE_KEY);
  _vm.$cookies.remove(TERM_FLAG);
  _vm.$util.link("logout");
  _vm.$store.commit('user', {});
}
