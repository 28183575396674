var _vm = null

import {
  formmasks
} from './formmasks'
import {
  formrules
} from './formrules'

const mercadoPago = {
  descricao: "Mercado Pago",
  img: "logoMercadoPago.jpg",
  weblink: "https://www.mercadopago.com.br",
  ios: {
    id: "925436649"
  },
  android: {
    host: "qr",
    scheme: "mercadopago",
    package: "com.mercadopago.wallet"
  }
}

export default {
  formrules,
  formmasks,
  //
  mercadoPago,
  //
  formatCurrencyToBR(number) {
    return number ? number.toFixed(2)
      .toString()
      .replace(".", ",") : number;
  },
  formatCurrencyFromBR(number) {
    return number ? parseFloat(number.replace(",", ".")) : number;
  },
  vueFormatDateToBR(date) {
    if (!date) return null;
    const pdate = date.substring(0, 10);
    const [year, month, day] = pdate.split("-");
    return day.padStart(2, '0') + '/' + month.padStart(2, '0') + '/' + year;
  },
  vueFormatDateFromBR(date) {
    if (!date) return null;
    const [day, month, year] = date.split("/");
    return year + '-' + month.padStart(2, '0') + '-' + day.padStart(2, '0');
  },
  strToBool(str) {
    return (str != null && str != undefined && (str.toLowerCase().trim() == 'true' || str.toLowerCase().trim() == '1') ? true : false)
  },
  formatCNPJ(cnpj) {
    return cnpj ? cnpj
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5") : ''
  },
  formatCPF(cpf) {
    return cpf ? cpf
      .replace(/\D/g, '')
      .replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.$2.$3-$4") : ''
  },
  isMobile() {
    // aler(navigator.userAgent)
    return window.innerWidth <= 960;
  },
  isIosMobile() {
    // alert(navigator.userAgent)
    return (/IPHONE|IPAD|IPOD/i.test(navigator.userAgent.toUpperCase()))
  },
  //is PWA app installed
  isInStandaloneMode() {
    return (window.matchMedia('(display-mode: standalone)').matches) || (('standalone' in navigator) && (navigator.standalone))
  },
  link(name, params, query) {
    return new Promise(resolve => {
      var rota = {
        name: name
      }

      if (params != undefined)
        rota.params = params

      if (query)
        rota.query = query;

      if (name !== _vm.$route.name) {
        _vm.$router.push(rota)
      } else {
        // Necessary for not vue router error
        _vm.$router.push({
          ...rota,
          query: {
            ...rota.query,
            t: new Date().getTime() // With this, the route is allways different
          }
        })
      }
      resolve()
    })
  },
  linkRedirect(url) {
    if (!url.includes('http')) {
      url = window.location.origin + '/' + url
    }

    window.location.href = url;
  },
  linkNewTab(url) {
    if (!url.includes('://')) {
      url = window.location.origin + '/' + url
    }
    if (this.isIosMobile()) {
      window.location.assign(url)
    } else {
      window.open(url, '_blank');
    }
  },
  removeAccents(str) {
    return str ? str.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : str
  },
  checkIndex() {
    var fromIndex = !!(_vm.$route.redirectedFrom && _vm.$route.redirectedFrom.includes("index."))
    return fromIndex
  },
  isRoute(routename) {
    return (_vm.$route && _vm.$route.name == routename)
  },
  vue() {
    return _vm
  },
  round(n, places) {
    return +(Math.round(n + "e+" + places) + "e-" + places)
  },
  breakSpace(str, breaker) {
    return str ? str.replace(" ", (breaker ? breaker : "<br />")) : str
  },
  getUrlApp(item) {
    var link = "";

    if (!_vm.$util.isMobile()) {
      //WEB
      link = item.weblink;
    } else if (_vm.$util.isIosMobile()) {
      //IOS
      if (item.ios.scheme) {
        //scheme://resource
        link = item.ios.scheme + "://main";
      } else {
        link = link = "https://apps.apple.com/br/app/id" + item.ios.id;
      }
    } else {
      //ANDROID
      var playstoreurl =
        "https://play.google.com/store/apps/details?id=" +
        item.android.package;

      if (item.android.link) {
        link = item.android.link;
      } else if (item.android.scheme) {
        link =
          "intent://" +
          (item.android.host ? item.android.host + "/d" : "") +
          "#Intent;scheme=" +
          item.android.scheme +
          ";package=" +
          item.android.package +
          ";S.browser_fallback_url=" +
          playstoreurl +
          ";end";
      } else {
        link = playstoreurl;
      }
    }

    return link;
  },
  install(Vue, config) {
    _vm = config.vm
    Vue.prototype.$util = this
  },
  validateCPF(cpf) {

    if(!cpf) return false;

    cpf = cpf.replace(/[^\d]+/g,'');
    if(cpf == '') return false;
    // Elimina CPFs invalidos conhecidos
    if (cpf.length != 11 ||
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999")
      return false;
    // Valida 1o digito
    let add = 0;
    let rev = 0;
    for (var i=0; i < 9; i ++)
      add += parseInt(cpf.charAt(i)) * (10 - i);
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11)
      rev = 0;
    if (rev != parseInt(cpf.charAt(9)))
      return false;
    // Valida 2o digito
    add = 0;
    for (i = 0; i < 10; i ++)
      add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11)
      rev = 0;
    if (rev != parseInt(cpf.charAt(10)))
      return false;
    return true;
  },
  removerPontuacaoCPF(cpf) {
    if(cpf != null && cpf.trim() != "")
      return cpf.match(/\d/g).join("");
    else
      return cpf;
  },
  showTour () {
    localStorage.setItem('showTour', true)
  },
}
