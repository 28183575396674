/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import Vue from 'vue'
import './plugins/fontawesome'
import 'babel-polyfill';
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import VueTour from 'vue-tour';
require('vue-tour/dist/vue-tour.css')
Vue.use(VueTour)

import infiniteScroll from 'vue-infinite-scroll'
import { MediaQueries } from 'vue-media-queries'
import store from './store/store.js'
import router from './router/router.js'
import './plugins/filters'

import './registerServiceWorker.js'

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import App from './App'
import {
  mapMutations
} from 'vuex';

import genericButton from "./components/generic-btn/index.vue";
Vue.component('generic-btn', genericButton);

import * as VueGoogleMaps from 'vue2-google-maps-withscopedautocomp'
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBQG_E6ulr0eFcSZWyqzVS9UfKYGV0DkAw',
    libraries: 'places',
  },
  installComponents: true
});

import Geocoder from "@pderas/vue2-geocoder";
Vue.use(Geocoder, {
  defaultCountryCode: 'BR',
  defaultLanguage: 'pt-br',
  defaultMode: 'address',
  googleMapsApiKey: 'AIzaSyBQG_E6ulr0eFcSZWyqzVS9UfKYGV0DkAw'
});

Vue.config.productionTip = false

Vue.component('v-style', {
  render: function (createElement) {
    return createElement('style', this.$slots.default)
  }
});

import customvuetify from './plugins/vuetify'

const mediaQueries = new MediaQueries();
Vue.use(mediaQueries);
Vue.use(infiniteScroll);

import device from "vue-device-detector"
Vue.use(device)

Vue.filter('cpf', (documentId) => {
  return documentId.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
});

Sentry.init({
  Vue,
  environment: process.env.NODE_ENV,
  dsn: "https://d44771ea4f544b079e91a5cc466c04db@o955208.ingest.sentry.io/5904503",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["app.dev.euprotegido.firecodeit.net", "app.stg.euprotegido.firecodeit.net","app.euprotegido.com.br", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});


var vm = new Vue({
  store,
  router,
  render: h => h(App),
  vuetify: customvuetify,
  mediaQueries,
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize();

    window.addEventListener('scroll', this.handleScroll)
    this.handleScroll();

    window.addEventListener('beforeinstallprompt', (event) => {
      try {
        event.prompt();
        //eslint-disable-next-line
      } catch (err) {}
    });

    window.addEventListener('message', function (msg) {
      if (msg.data.event === 'callbackServiceLogin') {
        try {

          let options = {
            socialType: msg.data.socialType,
            profilePicture: msg.data.profilePicture
          };

          $auth.createSession({
            access_token: msg.data.tokenSecurityService
          }, options);
        } catch (e) {
          return $router.push('/login');
        }
      }else if(msg.data.event === 'serviceDefaultReturn'){
        $router.push('/');
      }
    });

    // window.addEventListener("orientationchange", function (e) {
    //   screen.orientation.lock("portrait")
    // });
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    ...mapMutations(['handleResize', 'handleScroll'])
  }
})

import VueCookies from 'vue-cookies';
Vue.use(VueCookies);
VueCookies.config('1d')

import auth from './common/auth'
Vue.use(auth, {
  vm: vm
})

import util from './common/util.js'
import $auth from "./common/auth";
import $router from "./router/router";
Vue.use(util, {
  vm: vm
})

vm.$mount('#app')
