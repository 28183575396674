<template class="container-background">
  <v-container fluid style="background-color: white; height: 100vh">
    <v-row no-gutters>
      <v-col
          v-for="appConfiguration in appConfigurations"
          :key="appConfiguration.key"
          cols="12"
          lg="6"

      >
        <config-field :app-configuration="appConfiguration" :editing="editing" v-on:startEdit="startEdit" />
      </v-col>
    </v-row>
    <v-row no-gutters class="text-center" v-show="appConfigurations.length > 0 && editing">
      <v-col>
        <v-btn @click="cancelEdit" depressed class="mr-2">Cancelar</v-btn>
        <v-btn @click="save" depressed color="rgb(84,79,105)" style="color: white">Salvar</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {getAppConfigurations, saveAppConfigurations } from '../../common/securityserver';
import {mapMutations} from "vuex";
import ConfigField from "@/view/config-app/appConfigurationField";

export default {
  name: "configFinancialGroup",
  components: {ConfigField},
  data: () => ({
    token: "",
    entityID: "",
    entityType: "",
    appConfigurations: [],
    editing: false
  }),
  methods: {
    ...mapMutations(["showmsg", "loading"]),
    save(){
      this.loading(true);
      for(const appConfiguration of this.appConfigurations){
        if(appConfiguration.fieldType == "COMBO"){
          appConfiguration.value = appConfiguration.value.join(",");
        }
      }
      saveAppConfigurations(this.appConfigurations, this.token)
          .then((response) =>{

            for(const appConfiguration of response.data){
              if(appConfiguration.fieldType == 'COMBO' || appConfiguration.fieldType == 'SELECT' ){
                appConfiguration.options = appConfiguration.optionValues ? appConfiguration.optionValues.split(",") : [];
              }
              if(appConfiguration.fieldType == 'COMBO'){
                appConfiguration.value = appConfiguration.value ? appConfiguration.value.split(",") : [];
              }
            }

            this.appConfigurations = response.data;
            this.showmsg({ text: "As configurações foram salvas!", type: "success" });
          })
          .catch((error) => {
            this.showmsg({ text: "Erro ao salvar configurações!", type: "error" });
          })
          .finally(() => {
            this.loading(false);
            this.editing = false
          })
    },
    cancelEdit(){
      this.loadConfigurations();
      this.editing = false
    },
    startEdit(){
      this.editing = true
    },
    loadConfigurations(){
      this.loading(true);
      this.appConfigurations = []
      getAppConfigurations(this.entityID, this.entityType, this.token)
          .then((response) => {
            this.appConfigurations = response.data;
          })
          .catch((error) => {
            this.showmsg({ text: "Erro ao carregar configurações!", type: "error" });
          }).finally(() => {
            this.loading(false);
          })
    }
  },
  created() {
    this.entityType = this.$route.meta.entityType;
    const {token, recordId} = this.$route.query;
    this.token = token;
    this.entityID = recordId;
    this.loadConfigurations();
  },

}
</script>

<style scoped>

</style>
