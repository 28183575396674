<template>
    <v-snackbar
      v-model="snackWithButtons"
      :timeout="timeout"
      bottom
      color="tertiary"
    >
      <v-row justify="center">
        <v-col cols="7">
          <span class="black--text">{{ snackWithBtnText }}</span>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="10">
          <v-btn
              style="width: 117px"
            color="secondary"
            class="black--text mr-2 ml-1"
            @click="snackWithButtons = false"
          >Fechar
          </v-btn>
          <v-btn
            class="white--text"
            color="primary"
            @click.stop="refreshApp"

          >
            {{ snackBtnText }}
          </v-btn>
        </v-col>
      </v-row>
    </v-snackbar>
</template>

<script>
export default {
    name: "UpdateVersion",
    data() {
        return {
            refreshing: false,
            registration: null,
            snackBtnText: 'Atualizar',
            snackWithBtnText: 'Nova versão disponível!',
            snackWithButtons: false,
            timeout: -1,
        }
    },
    methods: {
        showRefreshUI(e) {
            // Display a snackbar inviting the user to refresh/reload the app due
            // to an app update being available.
            // The new service worker is installed, but not yet active.
            // Store the ServiceWorkerRegistration instance for later use.
            this.registration = e.detail;
            this.snackWithButtons = true;
        },
        refreshApp() {
            this.snackWithButtons = false;
            // Protect against missing registration.waiting.
            if (!this.registration || !this.registration.waiting) { return; }
            this.registration.waiting.postMessage('skipWaiting');
        },
    },
    created() {
        // Listen for swUpdated event and display refresh snackbar as required.
        document.addEventListener('swUpdated', this.showRefreshUI, { once: true });
        // Refresh all open app tabs when a new service worker is installed.
        navigator.serviceWorker.addEventListener('controllerchange', () => {
            if (this.refreshing) return;
            this.refreshing = true;
            window.location.reload();
        });
    },
}
</script>

<style scoped>

</style>
