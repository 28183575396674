<template>
  <v-container fluid style="background-color: white; height: 100%">
    <v-expansion-panels v-show="!isLoading">
      <v-expansion-panel class="pa-0">
        <v-expansion-panel-header style="font-size: 24px">
          Dados do Beneficiário

          <template v-slot:actions>
            <v-icon size="40">
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container class="pa-0 mt-0" fluid>
            <v-row
              style="font-size: 14px; color: rgba(0,0,0,.6)"
              align="center"
              justify="space-around"
            >
              <v-col cols="6">
                <span class="font-weight-bold pr-2">CPF: </span>
                {{ $util.formatCPF(beneficiaryData.personCPF) }}
              </v-col>
              <v-col cols="6">
                <span class="font-weight-bold pr-2">Nome: </span>
                {{ beneficiaryData.personName }}
              </v-col>
              <v-col cols="6">
                <span class="font-weight-bold pr-2">Gênero: </span>
                {{ beneficiaryData.personGender }}
              </v-col>
              <v-col cols="6">
                <span class="font-weight-bold pr-2">Data de Nascimento: </span>
                {{ moment(beneficiaryData.personBirthDate).format('DD/MM/YYYY') }}
              </v-col>
              <v-col cols="6">
                <span class="font-weight-bold pr-2">Cartão: </span>
                {{ beneficiaryData.cardNumber }}
              </v-col>
              <v-col cols="6">
                <span class="font-weight-bold pr-2">Plano: </span>
                {{ beneficiaryData.planName }}
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import { beneficiaryById } from '../../common/contract-service';
import { mapMutations } from 'vuex';
import moment from 'moment';

export default {
  name: 'beneficiaryDetail',
  data() {
    return {
      moment,
      beneficiaryId: null,
      token: null,
      beneficiaryData: {},
      isLoading: false,
    }
  },
  methods: {
    ...mapMutations(['showmsg', 'loading']),
    getBeneficiary() {
      this.loading(true);
      this.isLoading = true;
      beneficiaryById(this.beneficiaryId, this.token).then(res => {
        this.beneficiaryData = res.data;
        this.loading(false);
        this.isLoading = false;
      }).catch(err => {
        this.showmsg({ text: 'Erro ao carregar informações!', type: 'error' });
        this.loading(false);
        this.isLoading = false;
      })
    }
  },
  created() {
    let { beneficiaryId, token } = this.$route.query;

    this.beneficiaryId = beneficiaryId;
    this.token = token;

    this.getBeneficiary();
  }
}
</script>

<style scoped>

</style>
