import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/src/styles/main.sass'
import pt from 'vuetify/es5/locale/pt'

Vue.use(Vuetify)

export let eu_protegido_theme = {
  primary: '#292867',
  secondary: '#F4F8FF',
  tertiary: '#EA4965',
  accent: '#d02540',
  //
  red: '#FC4763',
  cyan: '#49E4EE',
  blue: '#4A499B',
  green: '#44AB96',
  neonGreen: '#7EEE49',
  orange: '#F4511E',
  yellow: '#FFBE86',
  purple: '#8E24AA',
  black: '#333333',
  gray1: '#858585',
  gray2: '#585865',
  gray3: '#868698',
  //
  appgradientcolor: 'linear-gradient(164deg, rgba(55,51,178,.4) 0%, rgba(68,61,246,.4) 40%, rgba(254,123,147,.5) 75%, rgba(255,255,255,.4) 91%)',
  appbgcolor: '#100d38',
  appbgcolor2: '#eee',
  whatsapp: '#00E676',
  google: '#4185f4',
  primary2: '#ea4965',
  secondary2: '#1d1938',
  purpleclean: '#2a244e'
}

export default new Vuetify({
  theme: {
    themes: {
      light: eu_protegido_theme,
      dark: eu_protegido_theme,
    },
    icons: {
      iconfont: 'fa',
    },
    options: {
      customProperties: true,
      minifyTheme: function (css) {
        return css.replace(/[\r\n|\r|\n]/g, '')
      },
    },
  },
  lang: {
    locales: { pt },
    current: 'pt',
  },
})
